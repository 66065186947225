
$fontPath: "../../assets/fonts/";

@font-face {
    font-family: "Canela Deck";
    src: url("#{$fontPath}/canela-deck/canela-deck_black.woff2") format("woff2"),
         url("#{$fontPath}/canela-deck/canela-deck_black.woff") format("woff");
    font-weight: 900;
    font-style: normal
}

@font-face {
    font-family: "NB International";
    src: url("#{$fontPath}/nb-international/nb-international_regular.woff2") format("woff2"),
         url("#{$fontPath}/nb-international/nb-international_regular.woff") format("woff");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: "NB International";
    src: url("#{$fontPath}/nb-international/nb-international_italic.woff2") format("woff2"),
         url("#{$fontPath}/nb-international/nb-international_italic.woff") format("woff");
    font-weight: 400;
    font-style: italic
}

@font-face {
    font-family: "NB International";
    src: url("#{$fontPath}/nb-international/nb-international_bold.woff2") format("woff2"),
         url("#{$fontPath}/nb-international/nb-international_bold.woff") format("woff");
    font-weight: 700;
    font-style: normal
}

@font-face {
    font-family: "NB International";
    src: url("#{$fontPath}/nb-international/nb-international_bold_italic.woff2") format("woff2"),
         url("#{$fontPath}/nb-international/nb-international_bold_italic.woff") format("woff");
    font-weight: 700;
    font-style: italic
}
