.devacurl-pro-dashboard {
  .card-header h6 {
    font-family: "Canela Deck", serif;
    text-transform: lowercase;
    font-weight: 900;
    padding: 5px 7px;
    background-color: #cf3;
    white-space: nowrap;
    line-height: 1.4211em;
    font-size: 13px;
    display: inline-block;
    position: absolute;
    top: 0;
    margin: 0;
  }

  .devacurl-academy-intro {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .card {
    position: relative;
    //padding: 0 !important;
    //margin: 0 15px;
    margin-bottom: 20px;

    .card-contents {
      height: 100%;
      border: 1px solid #b5b5b5;
    }

    h3,
    h6 {
      margin: 0;
      line-height: 1.4211em;
    }

    h3 {
      font-weight: 400;
      font-family: "NB International", "News Gothic MT", Helvetica, Arial,
        sans-serif;
      font-size: 19px;
    }

    h6 {
      font-family: "Canela Deck", serif;
      text-transform: lowercase;
      font-weight: 900;
      font-size: 13px;
    }

    .card-body {
      padding: 1em;
    }
  }
}
