
header[_ngcontent-sc1] {
    width: 100%;
    float: left;
    background-color: #000000;
    height: 108px;
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    header[_ngcontent-sc1] {
        height: 56px;
    }
}

header[_ngcontent-sc1] .inner[_ngcontent-sc1] {
    width: 100%;
    float: left;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0 20px;
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    header[_ngcontent-sc1] .inner[_ngcontent-sc1] {
        margin-top: 11px;
        margin-bottom: 11px;
    }
}

header[_ngcontent-sc1] .nav[_ngcontent-sc1] {
    width: 100%;
    float: left;
    max-width: 1160px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.close-button[_ngcontent-sc1] {
    color: #ffffff;
}

.close-button[_ngcontent-sc1] app-svg[_ngcontent-sc1] svg {
    width: 16px;
    height: 16px;
    margin: 2px 1.5px;
}

.logo[_ngcontent-sc1] app-svg[_ngcontent-sc1] svg {
    width: 189px;
    height: 45px;
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    .logo[_ngcontent-sc1] app-svg[_ngcontent-sc1] svg {
        margin-top: 4px;
        width: 107px;
        height: 27px;
    }
}

@media (min-width: 768px) and (max-width: 959px), (min-width: 960px) {
    .nav-links[_ngcontent-sc1] {
        flex: 1;
    }
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    .nav-links[_ngcontent-sc1] {
        position: absolute;
        top: 13px;
        width: 100%;
        left: 0;
    }
}

.scissor-person[_ngcontent-sc1] svg {
    width: 35px;
    height: 35px;
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    .scissor-person[_ngcontent-sc1] svg {
        width: 25px;
        height: 25px;
    }
}

.account[_ngcontent-sc1] .account-submenu {
    display: none;
}

@media (min-width: 768px) and (max-width: 959px), (min-width: 960px) {
    .account[_ngcontent-sc1] {
        position: relative;
    }

    .account[_ngcontent-sc1]:hover .account-submenu {
        display: block;
    }
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    .account[_ngcontent-sc1] {
        margin-right: -5px;
    }
}

app-header-links[_ngcontent-sc1] h2.h6 {
    font-family: "NB International", "News Gothic MT", "Helvetica", "Arial", "sans-serif" !important;
    text-transform: none !important;
}

@media (min-width: 768px) and (max-width: 959px), (min-width: 960px) {
    app-header-links[_ngcontent-sc1] h2.h6 {
        font-size: 13px !important;
    }
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    app-header-links[_ngcontent-sc1] h2.h6 {
        font-size: 16px !important;
    }
}

app-header-links[_ngcontent-sc1] .header-nav > li:not(:hover) .l-header__dropdown:before {
    height: 0;
}

@media (min-width: 768px) and (max-width: 959px), (min-width: 960px) {
    app-header-links[_ngcontent-sc1] .l-header__dropdown {
        top: 138px;
    }

    app-header-links[_ngcontent-sc1] .l-header__dropdown:before {
        content: '';
        position: absolute;
        top: -46px;
        left: 0;
        width: 100%;
        height: 46px;
        background: transparent;
    }

    app-header-links[_ngcontent-sc1] .l-header__dropdown .dropdown-container {
        margin-top: 0 !important;
    }
}


.l-header-banner-pro[_ngcontent-sc3] {
    width: 100%;
    float: left;
    background: #ccff33;
}

.l-header-banner-pro__container[_ngcontent-sc3] {
    width: 100%;
    float: left;
    display: flex;
    justify-content: center;
    height: 30px;
    padding-left: 20px;
    padding-right: 20px;
}

.promo-nav[_ngcontent-sc3] {
    width: 100%;
    float: left;
    max-width: 1160px;
    display: flex;
    align-items: center;
    height: 100%;
}


.button-common[_ngcontent-sc4] {
    position: relative;
    height: 2.75em;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #ffffff;
    border: 1px solid #000000;
    color: #000000;
    cursor: pointer;
    font-size: 16px;
    line-height: 2.75em;
    text-decoration: none;
    text-align: center;
    overflow: hidden;
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    .button-common[_ngcontent-sc4] {
        padding-left: 25px;
        padding-right: 25px;
    }
}

.button-theme[_ngcontent-sc4] {
    min-width: 138px;
    transition: background-color 0.2s;
}

.button-theme[_ngcontent-sc4]:hover, .button-theme[_ngcontent-sc4]:active {
    background-color: #ccff33;
}

.text-common[_ngcontent-sc4] {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0;
    background-color: transparent;
    border: 0;
    color: #000000;
    cursor: pointer;
    text-decoration: none;
}

.text-common[_ngcontent-sc4]:after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    transform-origin: left center;
}

.text-link-theme[_ngcontent-sc4] {
    font-size: 16px;
}

@media (min-width: 501px) and (max-width: 767px) {
    .text-link-theme[_ngcontent-sc4] {
        font-size: 15px;
    }
}

@media (min-width: 0) and (max-width: 500px) {
    .text-link-theme[_ngcontent-sc4] {
        font-size: 14px;
    }
}

.text-link-theme[_ngcontent-sc4]:hover:after {
    -webkit-animation-name: foo;
    animation-name: foo;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
}

@-webkit-keyframes foo {
    from {
        transform: scaleX(0);
    }
    to {
        transform: scaleX(1);
    }
}

@keyframes foo {
    from {
        transform: scaleX(0);
    }
    to {
        transform: scaleX(1);
    }
}

.text-link-theme[_ngcontent-sc4]:hover:after, .text-link-theme.active[_ngcontent-sc4]:after {
    bottom: 2px;
    height: 0.0938em;
    background-color: #000000;
    transform: scaleX(1);
}

.nav-link-theme[_ngcontent-sc4] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0;
    background-color: transparent;
    border: 0;
    color: #767676;
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
    transition: color 0.2s;
}

@media (min-width: 501px) and (max-width: 767px) {
    .nav-link-theme[_ngcontent-sc4] {
        font-size: 15px;
    }
}

@media (min-width: 0) and (max-width: 500px) {
    .nav-link-theme[_ngcontent-sc4] {
        font-size: 14px;
    }
}

.nav-link-theme[_ngcontent-sc4]:hover {
    color: #000000;
}

.nav-link-theme.light[_ngcontent-sc4] {
    color: #767676;
}

.nav-link-theme.light[_ngcontent-sc4]:hover {
    color: #ffffff;
}

.light[_ngcontent-sc4] {
    color: #ffffff;
}

.unstyled[_ngcontent-sc4] {
    background: transparent;
    color: inherit;
    border: 0;
    padding: 0;
    text-decoration: none;
    cursor: pointer;
}


.light[_ngcontent-sc8] {
    color: #ffffff;
}

.caption[_ngcontent-sc8] {
    font-size: 13px;
    line-height: 1.5385em;
}

.copy[_ngcontent-sc8] {
    font-size: 16px;
    line-height: 1.5em;
}


.l-footer-pro[_ngcontent-sc2] {
    width: 100%;
    float: left;
    padding-left: 20px;
    padding-right: 20px;
    background: #000000;
    color: #ffffff;
}

.l-footer-pro__email-signup[_ngcontent-sc2] {
    width: 33.33333333%;
    float: left;
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    .l-footer-pro__email-signup[_ngcontent-sc2] {
        width: 100%;
        float: left;
    }
}

.l-footer-pro__row[_ngcontent-sc2] {
    max-width: 1160px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding-top: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid #767676;
}

.l-footer-pro__row[_ngcontent-sc2]:after {
    content: " ";
    display: block;
    clear: both;
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    .l-footer-pro__row[_ngcontent-sc2] {
        padding-top: 30px;
        padding-bottom: 30px;
        flex-direction: column-reverse;
    }
}

.l-footer-pro__row--legal[_ngcontent-sc2] {
    padding-top: 20px;
    margin-bottom: 0;
    border-bottom: 0;
    display: flex;
}

@media (min-width: 768px) and (max-width: 959px), (min-width: 960px) {
    .l-footer-pro__row--legal[_ngcontent-sc2] {
        justify-content: flex-end;
    }
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    .l-footer-pro__row--legal[_ngcontent-sc2] {
        flex-direction: row;
        justify-content: center;
    }
}

.l-footer-pro__row--legal[_ngcontent-sc2] .legal-link[_ngcontent-sc2] {
    margin-right: 20px;
}

.l-footer-pro__row--legal[_ngcontent-sc2] .legal-link[_ngcontent-sc2]:last-of-type {
    margin-right: 0;
}

.l-footer-pro__list[_ngcontent-sc2] {
    margin-top: 10px;
}

.l-footer-pro__copyright[_ngcontent-sc2] {
    flex-grow: 1;
}

.l-footer-pro__links[_ngcontent-sc2] {
    width: 50%;
    float: left;
    flex-grow: 1;
}

@media (min-width: 768px) and (max-width: 959px) {
    .l-footer-pro__links[_ngcontent-sc2] {
        width: 75%;
        float: left;
    }
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    .l-footer-pro__links[_ngcontent-sc2] {
        width: 100%;
        float: left;
        padding-top: 30px;
        text-align: center;
    }
}

.l-footer-pro__social-icons[_ngcontent-sc2] {
    display: flex;
    color: #ffffff;
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    .l-footer-pro__social-icons[_ngcontent-sc2] {
        justify-content: center;
    }
}

.l-footer-pro__social-icon[_ngcontent-sc2] {
    margin-right: 30px;
    margin-top: 15px;
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    .l-footer-pro__social-icon[_ngcontent-sc2] {
        margin-right: 15px;
    }

    .l-footer-pro__social-icon[_ngcontent-sc2]:last-of-type {
        margin-right: 0;
    }
}

.l-footer-pro__social-icon[_ngcontent-sc2]:last-of-type {
    margin-right: 0;
}

.l-footer-pro__column[_ngcontent-sc2] {
    width: 33.33333333%;
    float: left;
    padding-right: 10px;
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    .l-footer-pro__column[_ngcontent-sc2] {
        width: 100%;
        float: left;
        margin-bottom: 20px;
    }

    .l-footer-pro__column[_ngcontent-sc2]:last-of-type {
        margin-bottom: 0;
    }
}

.l-footer-pro__column[_ngcontent-sc2] ul[_ngcontent-sc2] {
    margin-top: 20px;
}

.l-footer-pro__column[_ngcontent-sc2] ul[_ngcontent-sc2] li[_ngcontent-sc2] {
    margin-bottom: 10px;
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    .l-footer-pro__column[_ngcontent-sc2] ul[_ngcontent-sc2] li[_ngcontent-sc2] {
        margin-bottom: 20px;
    }
}

.spacer-height-small[_ngcontent-sc12] {
    height: 30px;
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    .spacer-height-small[_ngcontent-sc12] {
        height: 13.332px;
    }
}


.header-nav[_ngcontent-sc6] {
    width: 100%;
    float: left;
    display: -ms-flexbox;
    display: flex;
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    .header-nav[_ngcontent-sc6] {
        position: absolute;
        -ms-flex-direction: column;
        flex-direction: column;
        min-height: calc(100vh - 16px);
        top: 74px;
        left: 0;
        z-index: 10100;
    }
}


.account-submenu[_ngcontent-sc7] {
    position: absolute;
    top: 73px;
    right: 0;
    width: 185px;
    background-color: #ffffff;
    padding: 30px 20px;
    z-index: 10100;
}

@media (min-width: 768px) and (max-width: 959px), (min-width: 960px) {
    .account-submenu[_ngcontent-sc7]:before {
        content: '';
        position: absolute;
        top: -50px;
        left: 0;
        width: 100%;
        height: 50px;
        background: transparent;
    }
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    .account-submenu[_ngcontent-sc7] {
        top: 86px;
    }
}

.account-submenu[_ngcontent-sc7] .pro-subheader[_ngcontent-sc7] .h5 {
    margin-bottom: 20px;
    font-size: 13px;
    font-weight: 700;
}

.account-submenu[_ngcontent-sc7] app-clickable[_ngcontent-sc7]:not(:last-child) {
    display: block;
    margin-bottom: 10px;
}


.light[_nghost-sc21] .h2[_ngcontent-sc21], .light[_nghost-sc21] .h3[_ngcontent-sc21] {
    color: #ccff33;
}

.light[_nghost-sc21] .h5[_ngcontent-sc21], .light[_nghost-sc21] .h6[_ngcontent-sc21] {
    color: #ffffff;
}

.pro.light[_nghost-sc21] .h2[_ngcontent-sc21], .pro.light[_nghost-sc21] .h3[_ngcontent-sc21] {
    color: #ffffff;
}

.pro[_nghost-sc21] .h2[_ngcontent-sc21], .pro[_nghost-sc21] .h3[_ngcontent-sc21], .pro[_nghost-sc21] .h5[_ngcontent-sc21] {
    font-family: "NB International", "News Gothic MT", "Helvetica", "Arial", "sans-serif";
    font-weight: 300;
    text-transform: none;
}

.pro[_nghost-sc21] .h6[_ngcontent-sc21] {
    font-family: "Canela Deck", serif;
    text-transform: lowercase;
    font-weight: 900;
}

.pro[_nghost-sc21] .h3[_ngcontent-sc21] {
    line-height: 1.5em;
}

.pro[_nghost-sc21] .h5[_ngcontent-sc21] {
    font-size: 19px;
    font-weight: 400;
    line-height: 1.4211em;
}

.pro[_nghost-sc21] .h6[_ngcontent-sc21] {
    font-size: 19px;
    line-height: 1.4211em;
}

.h2[_ngcontent-sc21], .h3[_ngcontent-sc21], .h5[_ngcontent-sc21], .h6[_ngcontent-sc21] {
    display: inherit;
    color: inherit;
    margin: 0;
}

.h2[_ngcontent-sc21], .h3[_ngcontent-sc21], .h5[_ngcontent-sc21] {
    font-weight: 900;
    font-family: "Canela Deck", serif;
    text-transform: lowercase;
}

.h2[_ngcontent-sc21] {
    font-size: 45px;
    line-height: 1.1111em;
    padding-bottom: 0.1em;
}

@media (min-width: 0) and (max-width: 500px) {
    .h2[_ngcontent-sc21] {
        font-size: 30px;
        line-height: 1.1667em;
    }
}

.h3[_ngcontent-sc21] {
    font-size: 30px;
    line-height: 1.1667em;
    padding-bottom: 0.1em;
}

@media (min-width: 0) and (max-width: 500px) {
    .h3[_ngcontent-sc21] {
        font-size: 20px;
        line-height: 1.25em;
    }
}

.h5[_ngcontent-sc21] {
    font-size: 20px;
    line-height: 1.25em;
}

.h6[_ngcontent-sc21] {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5em;
}


@media (min-width: 768px) and (max-width: 959px), (min-width: 960px) {
    [_nghost-sc19] {
        margin-left: 7.22222222%;
    }

    .pro[_nghost-sc19] {
        margin-left: 30px;
    }

    [_nghost-sc19] .active
    app-header-dropdowns
    .l-header__dropdown
    > .dropdown-container {
        display: -ms-flexbox;
        display: flex;
    }
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    [_nghost-sc19] {
        position: relative;
        border-bottom: 1px solid #b5b5b5;
    }

    [_nghost-sc19] .active[_ngcontent-sc19] {
        padding-top: 10px;
    }

    [_nghost-sc19] .active[_ngcontent-sc19] app-header-dropdowns .l-header__dropdown > .dropdown-container {
        display: -ms-flexbox;
        display: flex;
    }

    [_nghost-sc19] .main-nav-link[_ngcontent-sc19] {
        display: block;
        padding: 18px;
    }

    [_nghost-sc19] .main-nav-link[_ngcontent-sc19] p {
        font-size: 19px;
        font-weight: 700;
    }
}

.category-link[_ngcontent-sc19] {
    margin: 0;
    padding: 0;
    background: #ffffff;
    text-indent: 0;
    list-style-type: none;
    transition: box-shadow 0.2s;
}

.category-link[_ngcontent-sc19] .submenu-caret[_ngcontent-sc19] {
    position: absolute;
    right: 20px;
    top: 33px;
    pointer-events: none;
    transform: translateY(-50%) rotate(-90deg);
}

.category-link.active[_ngcontent-sc19] .submenu-caret[_ngcontent-sc19] {
    top: 43px;
    transform: translateY(-50%) rotate(90deg);
}

.category-link[_ngcontent-sc19]:hover app-header-dropdowns[_ngcontent-sc19] .l-header__dropdown > .dropdown-container, .category-link.active[_ngcontent-sc19] app-header-dropdowns[_ngcontent-sc19] .l-header__dropdown > .dropdown-container {
    top: 0px;
}

.category-link[_ngcontent-sc19] app-clickable.pro[_ngcontent-sc19] {
    display: inline-block;
    background: black;
}

.category-link[_ngcontent-sc19] .main-nav-link[_ngcontent-sc19] * {
    white-space: nowrap;
}

.category-link[_ngcontent-sc19] > app-clickable[_ngcontent-sc19]:hover *[_ngcontent-sc19] > p, .category-link.active[_ngcontent-sc19] > app-clickable[_ngcontent-sc19] *[_ngcontent-sc19] > p, .category-link[_ngcontent-sc19] app-clickable[_ngcontent-sc19] .router-link-active {
    box-shadow: inset 0px -3px 0 0px #ccff33;
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    .category-link[_ngcontent-sc19] > app-clickable[_ngcontent-sc19] {
        display: block;
    }

    .category-link[_ngcontent-sc19] > app-clickable[_ngcontent-sc19] button, .category-link[_ngcontent-sc19] > app-clickable[_ngcontent-sc19] a {
        width: 100%;
        text-align: left;
    }
}


.light[_ngcontent-sc9] {
    color: #ffffff;
}

.caption[_ngcontent-sc9] {
    font-size: 13px;
    line-height: 1.5385em;
}

.copy[_ngcontent-sc9] {
    font-size: 16px;
    line-height: 1.5em;
}


.l-header__dropdown[_ngcontent-sc22] {
    display: -ms-flexbox;
    display: flex;
    background-color: #ffffff;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 1003;
}

@media (min-width: 768px) and (max-width: 959px), (min-width: 960px) {
    .l-header__dropdown[_ngcontent-sc22] {
        box-shadow: 0 4px 4px -4px #767676;
        padding: 0 20px;
    }
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    .l-header__dropdown[_ngcontent-sc22] {
        position: relative;
        padding: 0 15px;
    }
}

.l-header__dropdown[_ngcontent-sc22] .dropdown-container[_ngcontent-sc22] {
    position: relative;
    -ms-flex-direction: row;
    flex-direction: row;
    width: 100%;
    max-width: 1160px;
    margin-top: 15px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: none;
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    .l-header__dropdown[_ngcontent-sc22] .dropdown-container[_ngcontent-sc22] {
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 0;
    }
}

.l-header__dropdown[_ngcontent-sc22] .dropdown-container[_ngcontent-sc22] .column[_ngcontent-sc22] {
    -ms-flex: 1;
    flex: 1;
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    .l-header__dropdown[_ngcontent-sc22] .dropdown-container[_ngcontent-sc22] .column[_ngcontent-sc22] {
        margin-bottom: 20px;
    }
}

@media (min-width: 768px) and (max-width: 959px), (min-width: 960px) {
    .l-header__dropdown[_ngcontent-sc22] .dropdown-container[_ngcontent-sc22] .dividers[_ngcontent-sc22]:not(:first-child) {
        padding-left: 25px;
        border-left: 1px solid #b5b5b5;
    }
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    .l-header__dropdown[_ngcontent-sc22] .dropdown-container[_ngcontent-sc22] .dividers[_ngcontent-sc22]:not(:first-child) {
        padding-top: 30px;
        border-top: 1px solid #b5b5b5;
    }
}


.link-list[_ngcontent-sc23] {
    margin: 0;
    padding: 0;
    text-indent: 0;
    list-style-type: none;
}

app-clickable.link-list-clickable[_ngcontent-sc23] {
    display: inline-block;
}

@media (min-width: 768px) and (max-width: 959px), (min-width: 960px) {
    app-clickable.link-list-clickable[_ngcontent-sc23] {
        margin: 7px 0;
    }
}

@media (min-width: 768px) and (max-width: 959px), (min-width: 960px) {
    app-clickable.link-list-clickable[_ngcontent-sc23] .nav-link-theme {
        font-size: 0.8125em;
    }
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    app-clickable.link-list-clickable[_ngcontent-sc23] .nav-link-theme {
        font-size: 1em;
        padding: 12px 0;
    }
}


app-h6.heading.vertical-margin-small[_ngcontent-sc23] {
    margin-top: 0;
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    app-h6.heading.vertical-margin-small[_ngcontent-sc23] {
        margin-bottom: 10px;
    }
}

@media (min-width: 768px) and (max-width: 959px), (min-width: 960px) {
    app-h6.heading[_ngcontent-sc23] .h6 {
        font-size: 0.8125em;
    }
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    app-h6.heading[_ngcontent-sc23] .h6 {
        font-size: 1em;
    }
}


.light[_nghost-sc26] .h2[_ngcontent-sc26], .light[_nghost-sc26] .h3[_ngcontent-sc26] {
    color: #ccff33;
}

.light[_nghost-sc26] .h5[_ngcontent-sc26], .light[_nghost-sc26] .h6[_ngcontent-sc26] {
    color: #ffffff;
}

.pro.light[_nghost-sc26] .h2[_ngcontent-sc26], .pro.light[_nghost-sc26] .h3[_ngcontent-sc26] {
    color: #ffffff;
}

.pro[_nghost-sc26] .h2[_ngcontent-sc26], .pro[_nghost-sc26] .h3[_ngcontent-sc26], .pro[_nghost-sc26] .h5[_ngcontent-sc26] {
    font-family: "NB International", "News Gothic MT", "Helvetica", "Arial", "sans-serif";
    font-weight: 300;
    text-transform: none;
}

.pro[_nghost-sc26] .h6[_ngcontent-sc26] {
    font-family: "Canela Deck", serif;
    text-transform: lowercase;
    font-weight: 900;
}

.pro[_nghost-sc26] .h3[_ngcontent-sc26] {
    line-height: 1.5em;
}

.pro[_nghost-sc26] .h5[_ngcontent-sc26] {
    font-size: 19px;
    font-weight: 400;
    line-height: 1.4211em;
}

.pro[_nghost-sc26] .h6[_ngcontent-sc26] {
    font-size: 19px;
    line-height: 1.4211em;
}

.h2[_ngcontent-sc26], .h3[_ngcontent-sc26], .h5[_ngcontent-sc26], .h6[_ngcontent-sc26] {
    display: inherit;
    color: inherit;
    margin: 0;
}

.h2[_ngcontent-sc26], .h3[_ngcontent-sc26], .h5[_ngcontent-sc26] {
    font-weight: 900;
    font-family: "Canela Deck", serif;
    text-transform: lowercase;
}

.h2[_ngcontent-sc26] {
    font-size: 45px;
    line-height: 1.1111em;
    padding-bottom: 0.1em;
}

@media (min-width: 0) and (max-width: 500px) {
    .h2[_ngcontent-sc26] {
        font-size: 30px;
        line-height: 1.1667em;
    }
}

.h3[_ngcontent-sc26] {
    font-size: 30px;
    line-height: 1.1667em;
    padding-bottom: 0.1em;
}

@media (min-width: 0) and (max-width: 500px) {
    .h3[_ngcontent-sc26] {
        font-size: 20px;
        line-height: 1.25em;
    }
}

.h5[_ngcontent-sc26] {
    font-size: 20px;
    line-height: 1.25em;
}

.h6[_ngcontent-sc26] {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5em;
}


.big-image-block[_ngcontent-sc25] {
    display: block;
    max-width: 290px;
}

.big-image-block[_ngcontent-sc25] > a > span {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
}

.big-image-block[_ngcontent-sc25] > a > span > * {
    -ms-flex-order: 2;
    order: 2;
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    .big-image-block[_ngcontent-sc25] {
        padding-top: 10px;
        padding-bottom: 5px;
    }
}

.big-image-block[_ngcontent-sc25] app-image[_ngcontent-sc25] {
    display: block;
    margin-bottom: 10px;
    -ms-flex-order: 0;
    order: 0;
}

.big-image-block[_ngcontent-sc25] app-caption[_ngcontent-sc25] {
    display: block;
    margin-top: 5px;
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    .big-image-block[_ngcontent-sc25] app-caption[_ngcontent-sc25] {
        text-align: center;
    }

    .big-image-block[_ngcontent-sc25] app-caption[_ngcontent-sc25] .caption {
        font-size: 1em;
    }
}

.big-image-block[_ngcontent-sc25] app-h6[_ngcontent-sc25] {
    -ms-flex-order: 1;
    order: 1;
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    .big-image-block[_ngcontent-sc25] app-h6[_ngcontent-sc25] {
        text-align: center;
    }
}

.big-image-block[_ngcontent-sc25] app-clickable[_ngcontent-sc25] {
    display: inline-block;
    margin-top: 10px;
}

.big-image-block[_ngcontent-sc25] app-clickable[_ngcontent-sc25] button {
    font-size: 0.8125em;
}


app-h6.heading.vertical-margin-small[_ngcontent-sc25] {
    margin-top: 0;
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    app-h6.heading.vertical-margin-small[_ngcontent-sc25] {
        margin-bottom: 10px;
    }
}

@media (min-width: 768px) and (max-width: 959px), (min-width: 960px) {
    app-h6.heading[_ngcontent-sc25] .h6 {
        font-size: 0.8125em;
    }
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    app-h6.heading[_ngcontent-sc25] .h6 {
        font-size: 1em;
    }
}


cld-image[_ngcontent-sc27] {
    display: block;
}

img {
    width: 100%;
    height: auto;
}


@media (min-width: 768px) and (max-width: 959px), (min-width: 960px) {
    [_nghost-sc20] {
        margin-left: 7.22222222%;
    }

    .pro[_nghost-sc20] {
        margin-left: 30px;
    }

    [_nghost-sc20] .active
    app-header-dropdowns
    .l-header__dropdown
    > .dropdown-container {
        display: -ms-flexbox;
        display: flex;
    }
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    [_nghost-sc20] {
        position: relative;
        border-bottom: 1px solid #b5b5b5;
    }

    [_nghost-sc20] .active[_ngcontent-sc20] {
        padding-top: 10px;
    }

    [_nghost-sc20] .active[_ngcontent-sc20] app-header-dropdowns .l-header__dropdown > .dropdown-container {
        display: -ms-flexbox;
        display: flex;
    }

    [_nghost-sc20] .main-nav-link[_ngcontent-sc20] {
        display: block;
        padding: 18px;
    }

    [_nghost-sc20] .main-nav-link[_ngcontent-sc20] p {
        font-size: 19px;
        font-weight: 700;
    }
}

.category-link[_ngcontent-sc20] {
    margin: 0;
    padding: 0;
    background: #ffffff;
    text-indent: 0;
    list-style-type: none;
    transition: box-shadow 0.2s;
}

.category-link[_ngcontent-sc20] .submenu-caret[_ngcontent-sc20] {
    position: absolute;
    right: 20px;
    top: 33px;
    pointer-events: none;
    transform: translateY(-50%) rotate(-90deg);
}

.category-link.active[_ngcontent-sc20] .submenu-caret[_ngcontent-sc20] {
    top: 43px;
    transform: translateY(-50%) rotate(90deg);
}

.category-link[_ngcontent-sc20]:hover app-header-dropdowns[_ngcontent-sc20] .l-header__dropdown > .dropdown-container, .category-link.active[_ngcontent-sc20] app-header-dropdowns[_ngcontent-sc20] .l-header__dropdown > .dropdown-container {
    top: 0px;
}

.category-link[_ngcontent-sc20] app-clickable.pro[_ngcontent-sc20] {
    display: inline-block;
    background: black;
}

.category-link[_ngcontent-sc20] .main-nav-link[_ngcontent-sc20] * {
    white-space: nowrap;
}

.category-link[_ngcontent-sc20] > app-clickable[_ngcontent-sc20]:hover *[_ngcontent-sc20] > p, .category-link.active[_ngcontent-sc20] > app-clickable[_ngcontent-sc20] *[_ngcontent-sc20] > p, .category-link[_ngcontent-sc20] app-clickable[_ngcontent-sc20] .router-link-active {
    box-shadow: inset 0px -3px 0 0px #ccff33;
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    .category-link[_ngcontent-sc20] > app-clickable[_ngcontent-sc20] {
        display: block;
    }

    .category-link[_ngcontent-sc20] > app-clickable[_ngcontent-sc20] button, .category-link[_ngcontent-sc20] > app-clickable[_ngcontent-sc20] a {
        width: 100%;
        text-align: left;
    }
}

.l-footer__social-form-container[_ngcontent-sc10] {
    margin-top: 10px;
    margin-bottom: 24px;
}

.l-footer__social-form-container[_ngcontent-sc10] app-caption[_ngcontent-sc10] {
    color: #767676;
}

@media (min-width: 768px) and (max-width: 959px) {
    .l-footer__social-form[_ngcontent-sc10] {
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.l-footer__social-input[_ngcontent-sc10] {
    text-indent: 15px;
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
    border-left: 1px solid #000000;
}

@media (min-width: 768px) and (max-width: 959px) {
    .l-footer__social-input[_ngcontent-sc10] {
        width: 100%;
        float: left;
        min-height: 2.8517em;
        border-bottom: 0;
        border-right: 1px solid #000000;
    }
}

@media (min-width: 768px) and (max-width: 959px) {
    .l-footer__social-button[_ngcontent-sc10] {
        width: 100%;
        float: left;
    }
}

@media (min-width: 768px) and (max-width: 959px) {
    .l-footer__social-button[_ngcontent-sc10] .button-theme {
        min-width: 100%;
    }
}

section[_ngcontent-sc11] {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
}

@media (min-width: 768px) and (max-width: 959px), (min-width: 960px) {
    section[_ngcontent-sc11] {
        height: 574px;
    }
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    section[_ngcontent-sc11] {
        flex-direction: column;
    }
}

.image[_ngcontent-sc11] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    .image[_ngcontent-sc11] {
        position: relative;
    }
}

.image[_ngcontent-sc11] app-responsive-image[_ngcontent-sc11] img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 50% 50%;
    object-position: 50% 50%;
}

@media (min-width: 768px) and (max-width: 959px), (min-width: 960px) {
    app-h1[_ngcontent-sc11] {
        max-width: 72%;
        margin-left: auto;
        margin-right: auto;
    }

    app-h1[_ngcontent-sc11] * {
        color: #ccff33 !important;
    }
}

@media (min-width: 768px) and (max-width: 959px), (min-width: 960px) {
    app-h2[_ngcontent-sc11] {
        max-width: 72%;
    }

    app-h2[_ngcontent-sc11] h2 span {
        color: #ccff33;
    }
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    app-h1[_ngcontent-sc11] *, app-h2[_ngcontent-sc11] *, app-h5[_ngcontent-sc11] * {
        color: #000000 !important;
    }
}


.light[_nghost-sc29] .h2[_ngcontent-sc29], .light[_nghost-sc29] .h3[_ngcontent-sc29] {
    color: #ccff33;
}

.light[_nghost-sc29] .h5[_ngcontent-sc29], .light[_nghost-sc29] .h6[_ngcontent-sc29] {
    color: #ffffff;
}

.pro.light[_nghost-sc29] .h2[_ngcontent-sc29], .pro.light[_nghost-sc29] .h3[_ngcontent-sc29] {
    color: #ffffff;
}

.pro[_nghost-sc29] .h2[_ngcontent-sc29], .pro[_nghost-sc29] .h3[_ngcontent-sc29], .pro[_nghost-sc29] .h5[_ngcontent-sc29] {
    font-family: "NB International", "News Gothic MT", "Helvetica", "Arial", "sans-serif";
    font-weight: 300;
    text-transform: none;
}

.pro[_nghost-sc29] .h6[_ngcontent-sc29] {
    font-family: "Canela Deck", serif;
    text-transform: lowercase;
    font-weight: 900;
}

.pro[_nghost-sc29] .h3[_ngcontent-sc29] {
    line-height: 1.5em;
}

.pro[_nghost-sc29] .h5[_ngcontent-sc29] {
    font-size: 19px;
    font-weight: 400;
    line-height: 1.4211em;
}

.pro[_nghost-sc29] .h6[_ngcontent-sc29] {
    font-size: 19px;
    line-height: 1.4211em;
}

.h2[_ngcontent-sc29], .h3[_ngcontent-sc29], .h5[_ngcontent-sc29], .h6[_ngcontent-sc29] {
    display: inherit;
    color: inherit;
    margin: 0;
}

.h2[_ngcontent-sc29], .h3[_ngcontent-sc29], .h5[_ngcontent-sc29] {
    font-weight: 900;
    font-family: "Canela Deck", serif;
    text-transform: lowercase;
}

.h2[_ngcontent-sc29] {
    font-size: 45px;
    line-height: 1.1111em;
    padding-bottom: 0.1em;
}

@media (min-width: 0) and (max-width: 500px) {
    .h2[_ngcontent-sc29] {
        font-size: 30px;
        line-height: 1.1667em;
    }
}

.h3[_ngcontent-sc29] {
    font-size: 30px;
    line-height: 1.1667em;
    padding-bottom: 0.1em;
}

@media (min-width: 0) and (max-width: 500px) {
    .h3[_ngcontent-sc29] {
        font-size: 20px;
        line-height: 1.25em;
    }
}

.h5[_ngcontent-sc29] {
    font-size: 20px;
    line-height: 1.25em;
}

.h6[_ngcontent-sc29] {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5em;
}

app-h2[_ngcontent-sc13] {
    margin-top: -5px;
    margin-bottom: 20px;
}

app-home-course-card[_ngcontent-sc14] {
    display: flex;
    width: 100%;
    float: left;
    margin-bottom: 20px;
}

@media (min-width: 501px) and (max-width: 767px) {
    app-home-course-card[_ngcontent-sc14] {
        width: 47.22222222%;
        float: left;
        margin-left: 1.38888889%;
        margin-right: 1.38888889%;
    }
}

@media (min-width: 768px) and (max-width: 959px) {
    app-home-course-card[_ngcontent-sc14] {
        width: 47.22222222%;
        float: left;
        margin-left: 1.38888889%;
        margin-right: 1.38888889%;
    }
}

@media (min-width: 960px) {
    app-home-course-card[_ngcontent-sc14] {
        width: 22.22222222%;
        float: left;
        margin-left: 1.38888889%;
        margin-right: 1.38888889%;
    }
}

app-clickable[_ngcontent-sc14] {
    display: block;
    margin-top: 40px;
    text-align: center;
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    app-clickable[_ngcontent-sc14] {
        margin-top: 30px;
    }
}


article[_ngcontent-sc30] {
    position: relative;
    border: 1px solid #b5b5b5;
}

app-course-tag[_ngcontent-sc30] {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
}


app-h6[_ngcontent-sc31] {
    padding: 5px 7px;
    background-color: #ccff33;
    color: #000000;
    font-size: 13px;
    text-transform: lowercase;
    white-space: nowrap;
}

.title[_ngcontent-sc15] {
    text-align: center;
    color: #ccff33;
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    .title[_ngcontent-sc15] {
        margin-bottom: 10px;
    }
}


.light[_nghost-sc32] .h2[_ngcontent-sc32], .light[_nghost-sc32] .h3[_ngcontent-sc32] {
    color: #ccff33;
}

.light[_nghost-sc32] .h5[_ngcontent-sc32], .light[_nghost-sc32] .h6[_ngcontent-sc32] {
    color: #ffffff;
}

.pro.light[_nghost-sc32] .h2[_ngcontent-sc32], .pro.light[_nghost-sc32] .h3[_ngcontent-sc32] {
    color: #ffffff;
}

.pro[_nghost-sc32] .h2[_ngcontent-sc32], .pro[_nghost-sc32] .h3[_ngcontent-sc32], .pro[_nghost-sc32] .h5[_ngcontent-sc32] {
    font-family: "NB International", "News Gothic MT", "Helvetica", "Arial", "sans-serif";
    font-weight: 300;
    text-transform: none;
}

.pro[_nghost-sc32] .h6[_ngcontent-sc32] {
    font-family: "Canela Deck", serif;
    text-transform: lowercase;
    font-weight: 900;
}

.pro[_nghost-sc32] .h3[_ngcontent-sc32] {
    line-height: 1.5em;
}

.pro[_nghost-sc32] .h5[_ngcontent-sc32] {
    font-size: 19px;
    font-weight: 400;
    line-height: 1.4211em;
}

.pro[_nghost-sc32] .h6[_ngcontent-sc32] {
    font-size: 19px;
    line-height: 1.4211em;
}

.h2[_ngcontent-sc32], .h3[_ngcontent-sc32], .h5[_ngcontent-sc32], .h6[_ngcontent-sc32] {
    display: inherit;
    color: inherit;
    margin: 0;
}

.h2[_ngcontent-sc32], .h3[_ngcontent-sc32], .h5[_ngcontent-sc32] {
    font-weight: 900;
    font-family: "Canela Deck", serif;
    text-transform: lowercase;
}

.h2[_ngcontent-sc32] {
    font-size: 45px;
    line-height: 1.1111em;
    padding-bottom: 0.1em;
}

@media (min-width: 0) and (max-width: 500px) {
    .h2[_ngcontent-sc32] {
        font-size: 30px;
        line-height: 1.1667em;
    }
}

.h3[_ngcontent-sc32] {
    font-size: 30px;
    line-height: 1.1667em;
    padding-bottom: 0.1em;
}

@media (min-width: 0) and (max-width: 500px) {
    .h3[_ngcontent-sc32] {
        font-size: 20px;
        line-height: 1.25em;
    }
}

.h5[_ngcontent-sc32] {
    font-size: 20px;
    line-height: 1.25em;
}

.h6[_ngcontent-sc32] {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5em;
}

.image[_ngcontent-sc34] {
    width: 47.22222222%;
    float: left;
    margin-left: 1.38888889%;
    margin-right: 1.38888889%;
}

@media (min-width: 0) and (max-width: 500px), (min-width: 501px) and (max-width: 767px) {
    .image[_ngcontent-sc34] {
        width: 97.22222222%;
        float: left;
        margin-left: 1.38888889%;
        margin-right: 1.38888889%;
    }
}

.text[_ngcontent-sc34] {
    margin: 20px 0 30px;
}

.light[_ngcontent-sc36] {
    color: #ffffff;
}

.caption[_ngcontent-sc36] {
    font-size: 13px;
    line-height: 1.5385em;
}

.copy[_ngcontent-sc36] {
    font-size: 16px;
    line-height: 1.5em;
}

