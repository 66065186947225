.having-issues {
  max-width: 400px;
  margin: 0 auto;
  padding: 10px;
  background-color: #ccff33;
  text-align: center;
  font-family: montserrat, Arial, Helvetica, sans-serif;
  font-size: 12px;

  & a {
    font-weight: 600;
  }

  &:first-child {
    margin-bottom: 10px;
  }

  &:last-child {
    margin-top: 10px;
  }

  & > p ~ p {
    margin-top: 5px;
  }
}

#help-links-container {
  & > :nth-child(2) {
    font-weight: 600;
  }
}