
.devacurl-pro-login #okta-sign-in {

  min-height: 450px;
  color: #000000;
  overflow: visible;

  h1, h2, h3 {
    font-family: "NB International", "News Gothic MT", Helvetica, Arial, sans-serif;
    font-size: 60px;
    font-weight: 300;
    line-height: 1.0833em;
    text-transform: none;
  }

  .focused-input, .link.help:focus {
    box-shadow: none;
    outline: initial;
  }

  label, h2, #auth-footer a.link {
    border: 0px none;
    box-shadow: none;
    animation: 1s fadeIn;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(50%);
      outline: none;
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &.auth-container {
    background-color: transparent;
    border: 0px none;
    margin: 0 auto;
    box-shadow: none;

    .auth-content {
      margin: 5px;
    }

    .button-primary, .button.button-primary.link-button-disabled {
      height: 2.75em;
      padding-left: 30px;
      padding-right: 30px;
      background: #ffffff;
      border: 1px solid #000000;
      color: #000000;

      box-shadow: 5px 5px 0 0 #ccff33;
      transform: translate(-5px, -5px);
      transition: transform .2s, box-shadow .2s;
      margin-left: 5px;
      font-size: 16px;
      font-family: "NB International", "News Gothic MT", Helvetica, Arial, sans-serif;
    }

    .button-primary:hover {
      transform: translate(-1px, -1px);
      box-shadow: 1px 1px 0 0 #ccff33;
    }

    .okta-sign-in-header, .auth-content {
      border: 0 none;
      padding: 0;
    }

    .okta-form-label {
      color: #000000;
    }

    input:focus {
      outline: auto 5px -webkit-focus-ring-color;
    }

    .o-form-input-name-remember {
      label {
        background-image: none;
        padding-left: 28px;
        line-height: 20px;
        color: #000000;

        &:before {
          content: ".";
          color: #fff;
          display: inline-block;
          width: 20px;
          height: 20px;
          border: 1px solid #000000;
          stroke-width: 5;
          transform-origin: 50% 50%;
          stroke-dasharray: 48;
          stroke-dashoffset: 48;
          margin-right: 8px;
          position: absolute;
          left: 0;
        }

        &.checked:before {
          background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' %3E%3Cpath d='M24,4.68,7.67,22,0,13l1.74-1.49,6,7L22.27,3.06Z' %3E%3C/path%3E%3C/svg%3E") center center no-repeat;
          box-shadow: 2px 2px 0 0 #ccff33;
        }
      }

      input {
        width: 20px;
        height: 20px;
        transform-origin: 50% 50%;
        opacity: 0;
      }
    }

    a.link {
      position: relative;
      color: #000000;

      &:hover {
        text-decoration: none;
      }

      &:after {
        bottom: 0;
        height: .0938em;
        background-color: #000000;
        transform: scaleX(1);
        content: '';
        position: absolute;
        right: 0;
        left: 0;
        transform-origin: left center;
      }

      &:hover:after {
        -webkit-animation-name: foo;
        animation-name: foo;
        -webkit-animation-duration: 0.4s;
        animation-duration: 0.4s;
      }

      @keyframes foo {
        from {
          transform: scaleX(0);
        }
        to {
          transform: scaleX(1);
        }
      }
    }

    .input-fix {
      border-color: #000000;
      border-radius: 0;
    }
  }

  .button, .button.link-button {
    border-radius: 0;
  }

  label {
    font-family: "NB International", "News Gothic MT", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 13px;
  }
}
