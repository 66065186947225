/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
html {
    font-family: "NB International", "News Gothic MT", Helvetica, Arial, sans-serif;
    font-weight: 400;
    line-height: 1.15;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box
}

body {
    margin: 0;
    color: #000000
}

p {
    margin: 0
}

article, figcaption, footer, header, main, nav, section {
    display: block
}

a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects
}

 strong {
    font-weight: bolder
}

dfn {
    font-style: italic
}

svg:not(:root) {
    overflow: hidden
}

button, input {
    line-height: 1.15;
    font-family: "NB International", "News Gothic MT", Helvetica, Arial, sans-serif;
    font-size: 100%;
    margin: 0;
}

button {
    overflow: visible;
    font-weight: unset
}

button {
    text-transform: none
}

[type=reset], [type=submit], button, html [type=button] {
    -webkit-appearance: button
}

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
    border-style: none;
    padding: 0
}

[type=button]:-moz-focusring, [type=reset]:-moz-focusring, [type=submit]:-moz-focusring, button:-moz-focusring {
    outline: ButtonText dotted 1px
}

input {
    overflow: visible;
}

input.placeholder {
    color: #b5b5b5
}

input:-moz-placeholder {
    color: #b5b5b5
}

input::-moz-placeholder {
    color: #b5b5b5
}

input:-ms-input-placeholder {
    color: #b5b5b5
}

input::-webkit-input-placeholder {
    color: #b5b5b5
}

[type=checkbox], [type=radio] {
    box-sizing: border-box;
    padding: 0;
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

legend {
    box-sizing: border-box;
    display: table;
    max-width: 100%;
    padding: 0;
    color: inherit;
    white-space: normal
}

details, menu {
    display: block
}

canvas {
    display: inline-block
}

[aria-hidden=true], [hidden] {
    display: none
}

*, :after, :before {
    box-sizing: inherit;
}

 ul, ul ul {
    margin: 0;
    padding: 0
}

 ul li {
    vertical-align: top !important;
    margin: 0;
    padding: 0
}

a, a img {
    border: 0;
    cursor: pointer
}

a, a:hover, a:visited {
    color: inherit
}

a:focus, button:focus {
    outline: dotted thin
}

a img {
    text-decoration: none
}

img {
    vertical-align: middle;
    border: 0;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    -ms-interpolation-mode: bicubic;
    display: block
}

::-moz-selection {
    color: #000000;
    background: #e9ffaa !important
}

::-webkit-selection {
    color: #000000;
    background: #e9ffaa !important
}

::selection {
    color: #000000;
    background: #e9ffaa !important
}

.svg {
    display: inline-block;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor
}

.svg--white {
    fill: #ffffff
}

.svg--black {
    fill: #000000
}

.svg--xxsmall {
    width: 8px;
    height: 8px
}

.svg--small {
    width: 20px;
    height: 20px
}

.u-is-hidden {
    display: none;
    visibility: hidden;
    opacity: 0
}

.u-is-visible-desktop-only {
    display: block;
    visibility: visible
}

.u-is-visible-mobile-only {
    display: none !important;
    visibility: hidden
}

@media (min-width: 0) and (max-width: 500px),(min-width: 501px) and (max-width: 767px) {
    .u-is-visible-desktop-only {
        display: none !important;
        visibility: hidden
    }

    .u-is-visible-mobile-only {
        display: block !important;
        visibility: visible
    }
}

.u-visually-hidden {
    position: absolute;
    width: 1px;
    height: auto;
    margin: 0;
    padding: 0;
    border: 0;
    clip: rect(0 0 0 0);
    overflow: hidden;
    white-space: nowrap
}

.u-disabled {
    pointer-events: none;
    cursor: default
}

.u-list-reset, .u-list-reset li {
    margin: 0;
    padding: 0;
    text-indent: 0;
    list-style-type: none
}

.u-full-width {
    position: relative;
    width: 100%;
    float: left;
    display: block
}

.l-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    min-height: 450px;
}

.l-body {
    max-width: 2280px;
    margin-left: auto;
    margin-right: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-positive: 1;
    flex-grow: 1;
    height: 100%;
    /* min-height: 100vh; */
    text-align: left;
}

.l-body:after {
    content: " ";
    display: block;
    clear: both
}

.l-max-site-width {
    max-width: 2280px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    float: left
}

.l-max-site-width:after {
    content: " ";
    display: block;
    clear: both
}

.block {
    display: block
}

.inline-block {
    display: inline-block
}

.inline {
    display: inline
}

.vertical-margin-small {
    margin-top: 15px;
    margin-bottom: 15px
}

@media (min-width: 0) and (max-width: 500px),(min-width: 501px) and (max-width: 767px) {
    .l-body {
        width: 100%;
        float: left
    }
}

.background-color-transparent {
    background: 0 0
}

.form__element-container {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    min-height: 2.8517em;
    font-size: 14px;
    line-height: 2.8517em;
    overflow: hidden
}

.form__styles {
    width: 100%;
    float: left
}

.form__styles--no-border {
    border: 0
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@keyframes fadeIn {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@font-face {
    font-family: "NB International";
    src: url(/assets/fonts/nb_international_pro_light-webfont.woff2) format("woff2"), url(/assets/fonts/nb_international_pro_light-webfont.woff) format("woff");
    font-weight: 300;
    font-style: normal
}

@font-face {
    font-family: "NB International";
    src: url(/assets/fonts/nb_international_pro_light_italic-webfont.woff2) format("woff2"), url(/assets/fonts/nb_international_pro_light_italic-webfont.woff) format("woff");
    font-weight: 300;
    font-style: italic
}

